.suggest-container:hover {
    opacity: 1;
  }

.suggest-container:focus:active {
    opacity: 0.5;
}

.suggest-container:focus {
    opacity: 1;
    outline: none;
}

.suggest-asset {
    margin: 5px 0px;
}

@media (max-width:1200px) {
    .suggest-asset {
       display: none;
    }
}

.suggest-logo-from{
    width: 20px;
    height: 20px;
    padding-left: 10px;
    padding-top: 4px;
}

.suggest-logo-to{
    width: 20px;
    height: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 4px;
}

.suggest-arrow{
    padding: 0px 10px;
}

.suggest-container{
    margin: 10px 0px;
    display: flex;
    align-items: center;
    /* border-style: solid; */
    /* border-radius: 30px; */
    border-bottom: solid;
    border-width: 1px;
    cursor: pointer;
    opacity: 0.5;
    font-size: 14px;
    max-width: 190px;
    min-width: 100%;
    justify-content: space-evenly;
}

.suggest-asset-container-from{
    display: flex;
    width: 40%;
    justify-content: flex-end;
}
.suggest-asset-container-to{
    display: flex;
    width: 40%;
    justify-content: flex-start;
}