.dropdown-list-container{
  position: absolute;
  z-index: 1;
  margin-top: 2px;
  max-height: 300px;
  overflow-y: auto;
  width: 330px;
  padding-left: 20px;
}

.dd-list-item{
    padding-top: 3px;
    width: 100%;
    background: #000e37;
    color: white;
    border: none;
    height: 42px;
}

.dd-list-item:focus-visible{
  outline: none;
}

.dd-suggest-container{
  margin: 0px 13px;
}

.dd-suggest-container:focus-visible{
  outline: none;
  border-bottom-style: solid;
}