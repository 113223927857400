
.type-msg{
  width: 20%;
  font-weight: bold;
}

.msg-content{
  width: 70%;
  max-width: 240px;
}

.error-box{
  border-color: #df4c73;
}

.link-error{
  text-decoration: underline;
  color: #df4c73;
}

.error{
  color: #df4c73;
}

.loading-box{
  border-color: #ffffff;
}

.loading{
  color: #ffffff;
}

.link-loading{
  text-decoration: underline;
  color: #ffffff;
}

.success-box{
  border-color: #7ee6a2;
}

.success{
  color: #7ee6a2;
}

.link-success{
  text-decoration: underline;
  color: #7ee6a2;
}

.close-notification-button{
  font-size: 12px;
  background: transparent;
  border: none;
}

.close-notification-button:hover{
  cursor: pointer;
}

.msg-content{
  justify-content: center;
  display: flex;
  width: 100%;
}

.msg-box{
  font-size: 13px;
  display: flex;
  align-items: center;
  background: #0d1841;
  margin: 3px;
  border-radius: 15px;
  border: solid 0.3px;
  padding: 15px 20px;
  justify-content: space-around;
}
