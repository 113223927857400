.swap-container-input{
    display: flex;
    padding-bottom: 30px;
    font-size: 40px;
    padding-top: 5px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media (max-width:480px) {
  .swap-container-input{
    font-size: 25px;
  }
}

.max-button{
    background: transparent;
    color: white;
    border: solid;
    padding: 0px 2px;
    border-width: 1px;
    margin: 0px 4px;
    cursor: pointer;
}

.max-button:active{
    background: white;
    color: #000d37;
}

.current-balance{
    font-size: 15px;
    opacity: 0.5;
    min-height: 20px;
    display: inline-flex;
}


.current-balance:hover{
    cursor: pointer;
    opacity: 1;
}

.current-balance:active{
    opacity: 0.8;
}


.asset-outer-input{
    padding-top: 20px;
  }

.asset-name{
  margin-bottom: 30px;
}

.asset-outer{
    padding-top: 20px;
    min-width: 120px;
  }
  
  .asset-logo-from{
    padding-left: 10px;
  }

@media (max-width:480px) {
  .asset-logo-from{
    padding-left: 2px;
  }
}

  .asset-logo-to{
    padding-right: 10px;
  }

  input.amount-input{
    width: 120px;    
    padding-bottom: 8px;
    font-size: 34px;
    padding-bottom: 5px
}

input.amount-input-too-high{
  color: #ef5176;
  border-color: #ef5176
}

input.amount-input::placeholder {
  font-size: 34px;
}

.amount-calculated{
    min-width: 40px;   
    padding-bottom: 3px;
    font-size: 34px; 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}