.suggestion-category-name{
    opacity: 0.8;
    font-size: 12px
}

.balance-logo{
    width: 20px;
    height: 20px;
    padding-right: 8px;
}

.balance-amount{
    align-items: center;
    display: flex;
    min-width: 30%;
    justify-content: right;
    padding-right: 10px;
}

.balance-asset-container{
    display: flex;
    align-items: center;
    justify-content: start;
    min-width: 40%;   
}

@media (max-width:1400px) {
    .balance-asset{
        display: none;
    }
    .balance-asset-container{
        min-width: 10%;   
    }
    .balance-amount{
        min-width: 45%;
    }
}

.balance-container{
    border-bottom: solid;
    display: flex;
    font-size: 16px;
    padding: 3px 10px;
    align-items: end;
    opacity: 0.7;
    justify-content: space-between;
    margin: 10px 0px;
    border-width: 1px;
}

[data-testid=tail-spin-loading]{
    margin: auto;
}