.suggestion-category-name{
    opacity: 0.8;
    font-size: 12px
}

.suggestion-loading-container{
    display: grid;
    align-items: center;
    min-width: 250px;
    min-height: 100px;
}

.suggestion-category-name-outer{
    width: 100%;
    overflow-x: auto;
}

[data-testid=tail-spin-loading]{
    margin: auto;
}