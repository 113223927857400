.suggestions-container{
    max-height: 90%;
    display: flex;
    flex-flow: row wrap;
    max-width: 300px;
    justify-content: center;
    width: 16%;
    position: absolute;
    padding: 0px 50px;
    right: 0px;
  }

@media (max-width:880px) {
    .suggestions-container{
          display: none;
      }
}
